<template lang="pug">
.analytics_mode
  ui-tab(
    v-if="enableAnalyticsIndustry"
    size="medium"
    :to="routeAnalyticIndustries"
    :active="routeName === 'analyticsIndustry'"
  ) Отрасли
  ui-tab(
    v-if="enableAnalyticsSuppliers"
    size="medium"
    :to="routeAnalyticSuppliers"
    :active="routeName === 'analyticsSupplier'"
  ) Поставщики
  ui-tab(
    v-if="enableAnalyticsCustomers"
    size="medium"
    :to="routeAnalyticCustomers"
    :active="routeName === 'analyticsCustomer'"
  ) Заказчики
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import UiTab from "@/components/ui/button/UiTab.vue";

export default defineComponent({
  name: "AnalyticsModeTabs",
  components: {
    UiTab,
  },
  setup() {

    const route = useRoute();
    const routeName = computed(() => route.name);

    const {
      enableAnalyticsIndustry,
      enableAnalyticsCustomers,
      enableAnalyticsSuppliers,
    } = useUserAccess();

    const routeAnalyticIndustries = '/analytics/industry/lots';
    const routeAnalyticSuppliers = '/analytics/suppliers/won';
    const routeAnalyticCustomers = '/analytics/customers/completed';

    return {
      routeName,
      enableAnalyticsIndustry,
      enableAnalyticsCustomers,
      enableAnalyticsSuppliers,
      routeAnalyticIndustries,
      routeAnalyticSuppliers,
      routeAnalyticCustomers,
    };
  },
});
</script>

<style scoped lang="scss">
.analytics_mode {
  padding-top: 10px;
  display: flex;
  align-items: flex-end;
  gap: 1px;
}
</style>
