<template lang="pug">
#main_analytics
  h1.main_analytics__title Аналитика
  analytics-mode-selector
  router-view
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AnalyticsModeSelector from "@/components/pages/analytics/modes/AnalyticsModeSelector.vue";

export default defineComponent({
  name: "Analytics",
  components: {
    AnalyticsModeSelector,
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#main_analytics {
  @include analytic-page;
}

.main_analytics__title {
  padding-top: 35px;
  padding-bottom: 9px;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 24px;
}
</style>
